/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/toastr@2.1.4/build/toastr.min.css
 * - /npm/jquery.rateit@1.1.1/scripts/rateit.css
 * - /npm/timepicker@1.11.12/jquery.timepicker.min.css
 * - /npm/selectize@0.12.4/dist/css/selectize.css
 * - /npm/select2@4.0.6-rc.1/dist/css/select2.min.css
 * - /npm/bootstrap4c-custom-switch@1.0.8/dist/css/component-custom-switch.min.css
 * - /npm/bootstrap4c-dropzone@1.0.4/dist/css/component-dropzone.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
